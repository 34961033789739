import { useEffect, useMemo, useRef } from "react";

export function useDebounced<T>(ms: number, f: (param: T) => void) {
  const fStable = useRef(f);
  useEffect(() => {
    fStable.current = f;
  }, [f]);

  return useMemo(() => {
    let timer: ReturnType<typeof setTimeout>;
    return (param: T) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => fStable.current(param), ms);
    };
  }, [ms, fStable]);
}
